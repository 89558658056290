.sign-in-container {
  justify-content: center;
  display: flex;
  margin: 5rem auto;
  width: 1029px;
  height: 567px;
  flex-shrink: 0;
}

.form-container {
  padding: 4% 7%;
  width: 515px;
  height: 567px;
  flex-shrink: 0;
  border-radius: 0px 6px 6px 0px;
  border-top: 1px solid #D6D6D6;
  border-right: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6;
  background: #FFF;
}

.form-container-content {
  display: flex;
  width: 360px;
  max-width: 360px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-4xl, 32px);
}

.image-container {
  width: 50%;
  overflow: hidden;
}

.cover-image {
  width: 515px;
  height: 567px;
  flex-shrink: 0;
  border-radius: 6px 0px 0px 6px;
  border-top: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6;
  border-left: 1px solid #D6D6D6;
  background: var(--Primary, #086092);
  display: flex;
  padding: var(--spacing-10, 40px);
  align-items: center;
}

.cover-image-color {
  padding: var(--spacing-10, 80px);
}

.logo-image {
  width: 233px;
  height: 58.969px;
  margin-left: 66px;
}

.title {
  align-self: stretch;
  color: #000;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 12px;
  margin-top: 32px;
}

.description {
  align-self: stretch;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
}

.label {
  display: flex;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px;
}

.input-field {
  width: 100%;
  margin-bottom: 4%;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  border: 1px solid #D0D5DD;
  background-color: #FFF !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.error {
  color: red;
  margin-left: 1%;
}

.remember {
  margin: 16px 0;

  input {
    border-radius: 3px;
    margin-bottom: 2px;
  }
}

.submit-button {
  display: flex;
  padding: 10px var(--spacing-xl, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm, 6px);
  align-self: stretch;
  border-radius: 8px;
  background: #086092;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
}

.submit-button-text {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.form-border {
  height: 1px;
  background-color: #ccc;
  margin-top: 5%; 
}

.links-container {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  justify-content: space-between;

  .sub-heading {
    color: var(--Primary, #086092);
   font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
  }
}

.link {
  font-weight: 500;
  text-decoration-line: underline;
}

@media only screen and (width <= 768px) {
  .container {
    flex-direction: column;
    max-height: none;
    margin: 2rem 0.5rem;
  }

  .form-container {
    width: 100%;
    padding: 4%;
  }

  .image-container {
    display: none;
  }

  .title {
    font-size: x-large;
  }

  .submit-button {
    padding: 3%;
  }

  .links-container {
    flex-direction: column;
    align-items: center;
  }
}
